import _ from 'underscore';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import {
    Form,
    Input,
    Select,
    Button,
    Banner,
    EnhancedSelect,
    NumberFormatInput,
} from '@swingvy/design-system';
import { sendEvent, HrisEvents } from '@swingvy/frontend-util/data-events';
import { ConstantsUtil } from '@swingvy/frontend-util';

import API from 'js/network/IntroApi';
import RoutePath from 'js/app/RoutePath';
import ErrorModal from 'js/components/common/modal/ErrorModal';
import { registerObservable, authRegisterDoneObservable, refreshTokenObservable } from './util';

import 'style-loader!./register-content.less';

const { CommonEventName } = HrisEvents;
const { COUNTRY_CODE } = ConstantsUtil;
const MAILTO_SUPPORT = 'mailto:support@swingvy.com';
const DELIMITER = '|';

class RegisterContentView extends Component {
    constructor(props) {
        super(props);
        this.formEl = null;
        this.passwordId = null;

        this.state = {
            fullName: '',
            companyName: '',
            email: '',
            password: '',
            confirmPassword: '',
            noOfEmployee: '',
            phoneNumberObj: null,
            selectedIndustry: null,
            selectedCountry: null,
            selectedSubdivision: null,
            selectedPhoneCountry: null,

            isLoading: false,
            isPhoneNumberFocus: false,
            emailVerifyModal: {
                show: false,
                email: null,
                key: null,
            },
            showDuplicateEmailBanner: false,
        };
        this.registerCompanyHandler = this.registerCompanyHandler.bind(this);
        this.makeUniqKey = this.makeUniqCountryPhoneCode.bind(this);
    }

    registerCompanyHandler() {
        this.setState({ isLoading: true });

        const phoneCountryNumber = this.state.selectedPhoneCountry
            ? this.state.selectedPhoneCountry.phoneCode
            : '';
        const phoneLocalNumber = this.state.phoneNumberObj
            ? this.state.phoneNumberObj.formattedValue
            : '';
        const phoneNumber = `${phoneCountryNumber} ${phoneLocalNumber}`;

        Observable.of({
            fullName: this.state.fullName,
            companyName: this.state.companyName,
            email: this.state.email,
            password: this.state.password,
            noOfEmployee: this.state.noOfEmployee,
            phoneNumber,
            businessIndustryId: this.state.selectedIndustry,
            countryCode: this.state.selectedCountry,
            countrySubdivisionId: this.state.selectedSubdivision,
        })
            .concatMap((param) => registerObservable(API.REGISTER, param))
            .subscribe(
                (result) => {
                    try {
                        const { user, company } = result.session;
                        sendEvent(CommonEventName.SIGN_UP, {
                            user_id: user.id,
                            email: user.email,
                            name: user.name,
                            company_id: company.id,
                            company_name: company.name,
                            phone_number: phoneNumber,
                            business_industry: this.state.selectedIndustry,
                            number_of_employees: this.state.noOfEmployee,
                            country: this.state.selectedCountry,
                            register_type: 'general',
                        });
                    } catch (e) {
                        sendEvent(CommonEventName.SIGN_UP);
                        console.log(e);
                    }

                    this.setState({
                        isLoading: false,
                        emailVerifyModal: {
                            show: true,
                            key: result.key,
                            email: this.state.email,
                        },
                    });
                },
                (error) => {
                    const nextState = { isLoading: false };
                    switch (error.code) {
                        case 701:
                            nextState.showDuplicateEmailBanner = true;
                            break;
                    }
                    this.setState(nextState);
                },
            );
    }

    makeUniqCountryPhoneCode(country, delimiter = DELIMITER) {
        return `${country.name}${delimiter}${country.phoneCode}`;
    }

    render() {
        const {
            industries,
            countries,
            subdivisions,
            updateSubdivisions,
            allCountries,
            numberOfEmployeeOptions,
        } = this.props;
        const phoneOptions = allCountries.map(({ name, phoneCode }) => {
            return {
                value: this.makeUniqCountryPhoneCode({ name, phoneCode }),
                title: `${name} (${phoneCode})`,
                selectedTitle: phoneCode,
            };
        });

        return (
            <React.Fragment>
                <div className="content-box company-register-box crb">
                    <div className="content-inner-box">
                        <div className="title-area">
                            <div className="content-title">{i18n.t('register.title')}</div>
                        </div>
                        <div
                            className={`register-error-box ${
                                this.state.showDuplicateEmailBanner ? 'show-el' : ''
                            }`}
                        >
                            <Banner
                                type="error"
                                header={
                                    <span>
                                        {`${i18n.t(
                                            'register.error.duplicate_email_n_login.text1',
                                        )} `}
                                        <a href={RoutePath.LOGIN}>
                                            {i18n.t(
                                                'register.error.duplicate_email_n_login.text2_login',
                                            )}
                                        </a>
                                        {` ${i18n.t(
                                            'register.error.duplicate_email_n_login.text3',
                                        )} `}
                                        <a href={MAILTO_SUPPORT}>
                                            {i18n.t(
                                                'register.error.duplicate_email_n_login.text4_support_team',
                                            )}
                                        </a>
                                        {` ${i18n.t(
                                            'register.error.duplicate_email_n_login.text5',
                                        )}`}
                                    </span>
                                }
                                onClickClose={(ev) =>
                                    this.setState({ showDuplicateEmailBanner: false })
                                }
                            />
                        </div>
                        <Form
                            formRef={(el) => (this.formEl = el)}
                            submitHandler={this.registerCompanyHandler}
                        >
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <Input
                                        labelTitle={i18n.t('form.fullname.label')}
                                        required
                                        value={this.state.fullName}
                                        onChange={(value) => this.setState({ fullName: value })}
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <Input
                                        labelTitle={i18n.t('register.form.company_name.label')}
                                        required
                                        value={this.state.companyName}
                                        onChange={(value) => this.setState({ companyName: value })}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <Input
                                        labelTitle={i18n.t('form.email.label')}
                                        type="email"
                                        required
                                        value={this.state.email}
                                        onChange={(value) => this.setState({ email: value })}
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="crb__phone">
                                        <div className="crb__phone__select">
                                            <EnhancedSelect
                                                required
                                                labelTitle={
                                                    <span>{i18n.t('form.phone_number.label')}</span>
                                                }
                                                placeholder=""
                                                options={phoneOptions}
                                                value={
                                                    this.state.selectedPhoneCountry
                                                        ? this.makeUniqCountryPhoneCode(
                                                              this.state.selectedPhoneCountry,
                                                          )
                                                        : ''
                                                }
                                                onChange={({ value, title }) => {
                                                    this.setState({
                                                        selectedPhoneCountry: _.findWhere(
                                                            allCountries,
                                                            {
                                                                phoneCode:
                                                                    value.split(DELIMITER)[1],
                                                            },
                                                        ),
                                                    });
                                                }}
                                            />
                                        </div>
                                        <div
                                            className={`crb__phone__input ${
                                                this.state.isPhoneNumberFocus
                                                    ? 'crb__phone__input--focus'
                                                    : ''
                                            }`}
                                        >
                                            <NumberFormatInput
                                                required
                                                format="###-###-####"
                                                value={
                                                    this.state.phoneNumberObj
                                                        ? this.state.phoneNumberObj.value
                                                        : ''
                                                }
                                                onChange={(values) => {
                                                    this.setState({ phoneNumberObj: values });
                                                }}
                                                onFocus={() => {
                                                    this.setState({ isPhoneNumberFocus: true });
                                                }}
                                                onBlur={() => {
                                                    this.setState({ isPhoneNumberFocus: false });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <Input
                                        labelTitle={i18n.t('form.password.label')}
                                        type="password"
                                        onIdCreated={(id) => (this.passwordId = id)}
                                        required
                                        minLength={8}
                                        value={this.state.password}
                                        onChange={(value) => this.setState({ password: value })}
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <Input
                                        labelTitle={i18n.t('form.password.label_confirm')}
                                        type="password"
                                        equalTo={`#${this.passwordId}`}
                                        required
                                        minLength={8}
                                        value={this.state.confirmPassword}
                                        onChange={(value) =>
                                            this.setState({ confirmPassword: value })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <Select
                                        labelTitle={i18n.t('register.form.business_industry.label')}
                                        placeholder={i18n.t(
                                            'register.form.business_industry.placeholder',
                                        )}
                                        required
                                        options={industries}
                                        value={this.state.selectedIndustry}
                                        onChange={(option) =>
                                            this.setState({ selectedIndustry: option.value })
                                        }
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <Select
                                        labelTitle={i18n.t('form.no_of_employees.label')}
                                        placeholder={i18n.t('form.no_of_employees.placeholder')}
                                        required
                                        options={numberOfEmployeeOptions}
                                        value={this.state.noOfEmployee}
                                        onChange={(option) =>
                                            this.setState({ noOfEmployee: option.value })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <Select
                                        labelTitle={i18n.t('register.form.country.label')}
                                        placeholder={i18n.t('register.form.country.placeholder')}
                                        required
                                        options={countries}
                                        value={this.state.selectedCountry}
                                        onChange={(option) => {
                                            this.setState(
                                                {
                                                    selectedCountry: option.value,
                                                    selectedSubdivision: null,
                                                },
                                                () => {
                                                    updateSubdivisions(this.state.selectedCountry);
                                                },
                                            );
                                        }}
                                    />
                                </div>
                                {((this.state.selectedCountry &&
                                    this.state.selectedCountry === COUNTRY_CODE.MALAYSIA) ||
                                    this.state.selectedCountry === COUNTRY_CODE.TAIWAN) && (
                                    <div className="col-xs-12 col-sm-6">
                                        <Select
                                            labelTitle={
                                                this.state.selectedCountry === COUNTRY_CODE.TAIWAN
                                                    ? i18n.t('form.state.label_tw')
                                                    : i18n.t('register.form.state.label')
                                            }
                                            placeholder={i18n.t('register.form.state.placeholder')}
                                            required
                                            options={subdivisions}
                                            value={this.state.selectedSubdivision}
                                            onChange={(option) =>
                                                this.setState({ selectedSubdivision: option.value })
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </Form>
                        <div className="company-sign-up-box">
                            <div className="agree-check">
                                <span>
                                    {`${i18n.t('register.form.agreement.text1')} `}
                                    <a
                                        className="swv-btn swv-btn-links swv-theme-light"
                                        href={RoutePath.SWINGVY_LANDING.TOS}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {i18n.t('register.form.agreement.text2_link_terms')}
                                    </a>{' '}
                                    &{' '}
                                    <a
                                        className="swv-btn swv-btn-links swv-theme-light"
                                        href={RoutePath.SWINGVY_LANDING.PRIVACY}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {i18n.t(
                                            'register.form.agreement.text3_link_privacy_policy',
                                        )}
                                    </a>
                                    .
                                </span>
                            </div>
                            <Button
                                className="btn-company-sign-up"
                                type="primary"
                                size="large"
                                title={i18n.t('register.form.submit_button.title')}
                                isLoading={this.state.isLoading}
                                onClick={(ev) => $(this.formEl).submit()}
                            />
                        </div>
                    </div>
                </div>

                <div className="login-guide-box">
                    <div className="login-guide-text">
                        {i18n.t('register.txt_already_have_account')}
                        <a
                            className="swv-btn swv-btn-links swv-theme-light"
                            href={RoutePath.LOGIN}
                        >{` ${i18n.t('register.link_login')}`}</a>
                    </div>
                </div>

                <ErrorModal
                    className="gtm-verify-email-modal"
                    show={this.state.emailVerifyModal.show}
                    showCloseBtn={false}
                    type="standard"
                    title={i18n.t('register.verify_email_modal.header')}
                    message={i18n.t('register.verify_email_modal.message')}
                    cancelBtnTitle={i18n.t('register.verify_email_modal.button_title')}
                    onClickCancelBtn={() => {
                        this.setState(
                            {
                                emailVerifyModal: { ...this.state.emailVerifyModal, show: false },
                            },
                            () => {
                                const { emailVerifyModal } = this.state;
                                authRegisterDoneObservable({
                                    key: emailVerifyModal.key,
                                    email: emailVerifyModal.email,
                                })
                                    .concatMap((param) => refreshTokenObservable(param))
                                    .subscribe((param) => {
                                        document.location.href = '/main.html';
                                    });
                            },
                        );
                    }}
                />
            </React.Fragment>
        );
    }
}

RegisterContentView.propTypes = {
    industries: PropTypes.array,
    countries: PropTypes.array,
    subdivisions: PropTypes.array,
    updateSubdivisions: PropTypes.func,
    allCountries: PropTypes.array,
    numberOfEmployeeOptions: PropTypes.array,
};

export default RegisterContentView;
