import React from 'react';
import { LocationUtil } from '@swingvy/frontend-util';
import RoutePath from 'js/app/RoutePath';

class AbsView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hashPath: LocationUtil.getHashPath(),
        };
        this.hashPath = LocationUtil.getHashPath();
        this.mounted = false;

        this.getLoadingView = this.getLoadingView.bind(this);
        this.getForbiddenView = this.getForbiddenView.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    getLoadingView() {
        return (
            <div className="common_spinner">
                <div className="sk-spinner sk-spinner-wave">
                    <div className="sk-rect1" />
                    <div className="sk-rect2" />
                    <div className="sk-rect3" />
                    <div className="sk-rect4" />
                    <div className="sk-rect5" />
                </div>
            </div>
        );
    }

    getForbiddenView() {
        window.location.replace(RoutePath.HOME);
        return null;
    }

    render() {}
}

export default AbsView;
