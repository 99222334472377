import _ from 'underscore';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Observable } from 'rxjs';
import {
    Form,
    Input,
    Textarea,
    Button,
    Banner,
    Select,
    EnhancedSelect,
    NumberFormatInput,
} from '@swingvy/design-system';
import { sendEvent, HrisEvents } from '@swingvy/frontend-util/data-events';
import API from 'js/network/IntroApi';
import { ConstantsUtil } from '@swingvy/frontend-util';
import ErrorModal from 'js/components/common/modal/ErrorModal';
import { registerObservable, authRegisterDoneObservable, refreshTokenObservable } from './util';
import 'style-loader!./register-content.less';

const { CommonEventName } = HrisEvents;
const { COUNTRY_CODE } = ConstantsUtil;
const ICON_INFO = require('-!svg-react-loader!Icons/ic-info.svg');

const MAILTO_SUPPORT = 'mailto:support@swingvy.com';
const DELIMITER = '|';

class StartupRegisterContentView extends Component {
    constructor(props) {
        super(props);
        this.step1formEl = null;
        this.step2formEl = null;
        this.passwordId = null;

        this.state = {
            step: 1,

            /* step 1 */
            companyName: '',
            companyWebsite: '',
            selectedIndustry: null,
            selectedCountry: null,
            realCountryCode: null,
            selectedSubdivision: null,
            selectedYearFounded: null,
            selectedFundingReceived: null,
            noOfEmployee: '',
            whatCompanyDo: '',
            phoneNumberObj: null,
            selectedPhoneCountry: null,

            /* step 2 */
            fullName: '',
            email: '',
            password: '',
            confirmPassword: '',

            isLoading: false,
            isPhoneNumberFocus: false,
            emailVerifyModal: {
                show: false,
                email: null,
                key: null,
            },
            showDuplicateEmailBanner: false,
        };
        this.onClickStepTitle = this.onClickStepTitle.bind(this);
        this.renderStep1Form = this.renderStep1Form.bind(this);
        this.renderStep2Form = this.renderStep2Form.bind(this);
        this.step1FormSubmitHandler = this.step1FormSubmitHandler.bind(this);
        this.registerCompanyHandler = this.registerCompanyHandler.bind(this);
        this.makeUniqCountryPhoneCode = this.makeUniqCountryPhoneCode.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.geolocation) {
            if (nextProps.geolocation.realCountryCode !== this.state.realCountryCode) {
                this.setState({
                    realCountryCode: nextProps.geolocation.realCountryCode,
                    selectedCountry: nextProps.geolocation.countryCode,
                    selectedPhoneCountry: _.findWhere(nextProps.allCountries, {
                        code: nextProps.geolocation.realCountryCode,
                    }),
                });
            }
        }
        if (nextProps.subdivisions) {
            if (nextProps.subdivisions.length === 1) {
                this.setState({ selectedSubdivision: nextProps.subdivisions[0].value });
            }
        }
    }

    renderStep1Form() {
        const {
            geolocation,
            industries,
            countries,
            subdivisions,
            updateSubdivisions,
            yearFounded,
            fundingReceived,
            allCountries,
        } = this.props;

        const isFetching = !geolocation;
        const phoneOptions = allCountries.map(({ name, phoneCode }) => {
            return {
                value: this.makeUniqCountryPhoneCode({ name, phoneCode }),
                title: `${name} (${phoneCode})`,
                selectedTitle: phoneCode,
            };
        });

        return (
            <Form
                className={this.state.step === 1 ? '' : 'hide-form'}
                formRef={(el) => (this.step1formEl = el)}
                submitHandler={this.step1FormSubmitHandler}
            >
                <div className="row">
                    <div className="col-xs-12">
                        <Input
                            labelTitle={i18n.t('register.form.company_name.label')}
                            required
                            value={this.state.companyName}
                            onChange={(value) => this.setState({ companyName: value })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Input
                            labelTitle={i18n.t('register.register--startups.form.website.label')}
                            required
                            value={this.state.companyWebsite}
                            onChange={(value) => this.setState({ companyWebsite: value })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <div className="crb__phone">
                            <div className="crb__phone__select">
                                <EnhancedSelect
                                    required
                                    labelTitle={<span>{i18n.t('form.phone_number.label')}</span>}
                                    placeholder=""
                                    options={phoneOptions}
                                    value={
                                        this.state.selectedPhoneCountry
                                            ? this.makeUniqCountryPhoneCode(
                                                  this.state.selectedPhoneCountry,
                                              )
                                            : ''
                                    }
                                    onChange={({ value, title }) => {
                                        this.setState({
                                            selectedPhoneCountry: _.findWhere(allCountries, {
                                                phoneCode: value.split(DELIMITER)[1],
                                            }),
                                        });
                                    }}
                                />
                            </div>
                            <div
                                className={`crb__phone__input ${
                                    this.state.isPhoneNumberFocus ? 'crb__phone__input--focus' : ''
                                }`}
                            >
                                <NumberFormatInput
                                    required
                                    format="###-###-####"
                                    value={
                                        this.state.phoneNumberObj
                                            ? this.state.phoneNumberObj.value
                                            : ''
                                    }
                                    onChange={(values) => {
                                        this.setState({ phoneNumberObj: values });
                                    }}
                                    onFocus={() => {
                                        this.setState({ isPhoneNumberFocus: true });
                                    }}
                                    onBlur={() => {
                                        this.setState({ isPhoneNumberFocus: false });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Select
                            labelTitle={i18n.t('register.form.business_industry.label')}
                            placeholder={i18n.t('register.form.business_industry.placeholder')}
                            required
                            options={industries}
                            value={this.state.selectedIndustry}
                            onChange={(option) => this.setState({ selectedIndustry: option.value })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <Select
                            labelTitle={i18n.t('register.form.country.label')}
                            placeholder={i18n.t('register.form.country.placeholder')}
                            disabled
                            required
                            options={countries}
                            value={this.state.selectedCountry}
                            onChange={(option) => {
                                this.setState({ selectedCountry: option.value }, () => {
                                    updateSubdivisions(this.state.selectedCountry);
                                });
                            }}
                        />
                    </div>
                    {((this.state.selectedCountry &&
                        this.state.selectedCountry === COUNTRY_CODE.MALAYSIA) ||
                        this.state.selectedCountry === COUNTRY_CODE.TAIWAN) && (
                        <div className="col-xs-12 col-sm-6">
                            <Select
                                labelTitle={i18n.t('register.form.state.label')}
                                placeholder={i18n.t('register.form.state.placeholder')}
                                disabled={isFetching || subdivisions.length < 2}
                                required
                                options={subdivisions}
                                value={this.state.selectedSubdivision}
                                onChange={(option) =>
                                    this.setState({ selectedSubdivision: option.value })
                                }
                            />
                        </div>
                    )}
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <Select
                            labelTitle={i18n.t(
                                'register.register--startups.form.year_founded.label',
                            )}
                            placeholder={i18n.t(
                                'register.register--startups.form.year_founded.placeholder',
                            )}
                            required
                            options={yearFounded}
                            value={this.state.selectedYearFounded}
                            onChange={(option) =>
                                this.setState({ selectedYearFounded: option.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Select
                            labelTitle={i18n.t(
                                'register.register--startups.form.funding_received.label',
                            )}
                            placeholder={i18n.t(
                                'register.register--startups.form.funding_received.placeholder',
                            )}
                            required
                            options={fundingReceived}
                            value={this.state.selectedFundingReceived}
                            onChange={(option) =>
                                this.setState({ selectedFundingReceived: option.value })
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Input
                            labelTitle={i18n.t('form.no_of_employees.label')}
                            type="number"
                            min={1}
                            max={50000}
                            required
                            value={this.state.noOfEmployee}
                            onChange={(value) => this.setState({ noOfEmployee: value })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Textarea
                            className="_what_company_do_input_box"
                            labelTitle={i18n.t(
                                'register.register--startups.form.what_company_do.label',
                            )}
                            placeholder=""
                            required
                            autoExpand
                            value={this.state.whatCompanyDo}
                            onChange={(value) => {
                                const $grammerlyBox = $('._what_company_do_input_box').find(
                                    'grammarly-ghost',
                                );
                                if ($grammerlyBox.length > 0) {
                                    $grammerlyBox.find('div').css('visibility', 'hidden');
                                }

                                this.setState({ whatCompanyDo: value });
                            }}
                        />
                    </div>
                </div>
            </Form>
        );
    }

    step1FormSubmitHandler() {
        this.setState({ step: 2 });
    }

    renderStep2Form() {
        return (
            <Form
                className={this.state.step === 1 ? 'hide-form' : ''}
                formRef={(el) => (this.step2formEl = el)}
                submitHandler={this.registerCompanyHandler}
            >
                <div className="row">
                    <div className="col-xs-12">
                        <Input
                            labelTitle={i18n.t('form.fullname.label')}
                            required
                            value={this.state.fullName}
                            onChange={(value) => this.setState({ fullName: value })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Input
                            labelTitle={i18n.t('form.email.label')}
                            type="email"
                            required
                            value={this.state.email}
                            onChange={(value) => this.setState({ email: value })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Input
                            labelTitle={i18n.t('form.password.label')}
                            type="password"
                            onIdCreated={(id) => (this.passwordId = id)}
                            required
                            minLength={8}
                            value={this.state.password}
                            onChange={(value) => this.setState({ password: value })}
                        />
                    </div>
                    <div className="col-xs-12">
                        <Input
                            labelTitle={i18n.t('form.password.label_confirm')}
                            type="password"
                            equalTo={`#${this.passwordId}`}
                            required
                            minLength={8}
                            value={this.state.confirmPassword}
                            onChange={(value) => this.setState({ confirmPassword: value })}
                        />
                    </div>
                </div>
            </Form>
        );
    }

    registerCompanyHandler() {
        this.setState({ isLoading: true });

        const phoneCountryNumber = this.state.selectedPhoneCountry
            ? this.state.selectedPhoneCountry.phoneCode
            : '';
        const phoneLocalNumber = this.state.phoneNumberObj
            ? this.state.phoneNumberObj.formattedValue
            : '';
        const phoneNumber = `${phoneCountryNumber} ${phoneLocalNumber}`;

        Observable.of({
            companyName: this.state.companyName,
            companyWebsite: this.state.companyWebsite,
            yearFoundedId: this.state.selectedYearFounded,
            fundingReceivedId: this.state.selectedFundingReceived,
            noOfEmployee: this.state.noOfEmployee,
            whatCompanyDo: this.state.whatCompanyDo,

            fullName: this.state.fullName,
            email: this.state.email,
            password: this.state.password,
            businessIndustryId: this.state.selectedIndustry,
            countryCode: this.state.selectedCountry,
            realCountryCode: this.state.realCountryCode,
            countrySubdivisionId: this.state.selectedSubdivision,
            phoneNumber,
        })
            .concatMap((param) => registerObservable(API.STARTUP_PROGRAM_REGISTER, param))
            .subscribe(
                (result) => {
                    try {
                        const { user, company } = result.session;
                        sendEvent(CommonEventName.SIGN_UP, {
                            user_id: user.id,
                            email: user.email,
                            name: user.name,
                            company_id: company.id,
                            company_name: company.name,
                            phone_number: phoneNumber,
                            business_industry: this.state.selectedIndustry,
                            number_of_employees: this.state.noOfEmployee,
                            country: this.state.selectedCountry,
                            real_country: this.state.realCountryCode,
                            register_type: 'startup',
                        });
                    } catch (e) {
                        sendEvent(CommonEventName.SIGN_UP);
                        console.log(e);
                    }

                    this.setState({
                        step: 3,
                        isLoading: false,
                        emailVerifyModal: {
                            show: true,
                            key: result.key,
                            email: this.state.email,
                        },
                    });
                },
                (error) => {
                    const nextState = { isLoading: false };
                    switch (error.code) {
                        case 701:
                            nextState.showDuplicateEmailBanner = true;
                            break;
                    }
                    this.setState(nextState);
                },
            );
    }

    onClickStepTitle(clickedStep /* number */) {
        const currentStep = this.state.step;
        if (currentStep !== clickedStep) {
            if (currentStep === 1) {
                $(this.step1formEl).submit();
            } else {
                this.setState({ step: clickedStep });
            }
        }
    }

    makeUniqCountryPhoneCode(country, delimiter = DELIMITER) {
        return `${country.name}${delimiter}${country.phoneCode}`;
    }

    render() {
        const { geolocation } = this.props;
        const isFetching = !geolocation;
        const showUnsupportedCountryBanner =
            !isFetching && this.state.selectedCountry !== this.state.realCountryCode;

        return (
            <React.Fragment>
                <div className="content-box company-register-box startup-company-register-box">
                    <div
                        className={`content-inner-box ${
                            showUnsupportedCountryBanner ? 'show-unsupported-banner' : ''
                        }`}
                    >
                        <div className="title-area">
                            <div className="content-title">
                                {i18n.t('register.register--startups.title')}
                            </div>
                            <div className="content-sub-title">
                                <span
                                    className={`step-title ${
                                        this.state.step === 1 ? 'active' : ''
                                    }`}
                                    onClick={(ev) => this.onClickStepTitle(1)}
                                >
                                    {i18n.t('register.register--startups.subtitle_step1')}
                                </span>
                                <span className="step-title"> > </span>
                                <span
                                    className={`step-title ${
                                        this.state.step !== 1 ? 'active' : ''
                                    }`}
                                    onClick={(ev) => this.onClickStepTitle(2)}
                                >
                                    {i18n.t('register.register--startups.subtitle_step2')}
                                </span>
                            </div>
                        </div>
                        <div className="register-warning-box">
                            <ICON_INFO />
                            <div className="warning-text">
                                {`${i18n.t('register.email_exist_info.text1')} `}
                                <a href={MAILTO_SUPPORT}>
                                    {i18n.t('register.email_exist_info.text2_link')}
                                </a>
                                {` ${i18n.t('register.email_exist_info.text3')}`}
                            </div>
                        </div>
                        <div
                            className={`register-error-box ${
                                this.state.showDuplicateEmailBanner ? 'show-el' : ''
                            }`}
                        >
                            <Banner
                                type="error"
                                header={
                                    <span>
                                        {`${i18n.t('register.error.duplicate_email.text1')} `}
                                        <a href={MAILTO_SUPPORT}>
                                            {i18n.t(
                                                'register.error.duplicate_email.text2_support_team',
                                            )}
                                        </a>
                                        {` ${i18n.t('register.error.duplicate_email.text3')}`}
                                    </span>
                                }
                                onClickClose={(ev) =>
                                    this.setState({ showDuplicateEmailBanner: false })
                                }
                            />
                        </div>
                        <div className="register-unsupported-banner-box">
                            <Banner
                                type="warning"
                                header={
                                    <span>
                                        {i18n.t('register.error.no_support_country.banner.header')}
                                    </span>
                                }
                                body={
                                    <span>
                                        {i18n.t('register.error.no_support_country.banner.body')}
                                    </span>
                                }
                                showCloseIcon={false}
                            />
                        </div>
                        {this.renderStep1Form()}
                        {this.renderStep2Form()}

                        <div className="startups-company-sign-up-box">
                            {this.state.step !== 1 && (
                                <Button
                                    className="prev-step-btn"
                                    title={i18n.t('register.register--startups.prev_button.title')}
                                    onClick={(ev) => this.setState({ step: 1 })}
                                />
                            )}
                            <Button
                                className="next-step-btn"
                                type="primary"
                                title={
                                    this.state.step === 1
                                        ? i18n.t('register.register--startups.next_button.title')
                                        : i18n.t('register.register--startups.finish_button.title')
                                }
                                isLoading={this.state.isLoading}
                                onClick={(ev) => {
                                    if (this.state.step === 1) {
                                        $(this.step1formEl).submit();
                                    } else {
                                        $(this.step2formEl).submit();
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                <div className="login-guide-box">{/* just for margin */}</div>

                <ErrorModal
                    className="gtm-verify-email-modal gtm-verify-email-modal--startup"
                    show={this.state.emailVerifyModal.show}
                    showCloseBtn={false}
                    type="standard"
                    title={i18n.t('register.verify_email_modal.header')}
                    message={i18n.t('register.verify_email_modal.message')}
                    cancelBtnTitle={i18n.t('register.verify_email_modal.button_title')}
                    onClickCancelBtn={() => {
                        this.setState(
                            {
                                emailVerifyModal: { ...this.state.emailVerifyModal, show: false },
                            },
                            () => {
                                const { emailVerifyModal } = this.state;
                                authRegisterDoneObservable({
                                    key: emailVerifyModal.key,
                                    email: emailVerifyModal.email,
                                })
                                    .concatMap((param) => refreshTokenObservable(param))
                                    .subscribe((param) => {
                                        document.location.href = '/main.html';
                                    });
                            },
                        );
                    }}
                />
            </React.Fragment>
        );
    }
}

StartupRegisterContentView.propTypes = {
    geolocation: PropTypes.object,
    fundingReceived: PropTypes.array,
    yearFounded: PropTypes.array,
    industries: PropTypes.array,
    countries: PropTypes.array,
    subdivisions: PropTypes.array,
    updateSubdivisions: PropTypes.func,
    allCountries: PropTypes.array,
};

export default StartupRegisterContentView;
