import { Observable } from 'rxjs';
import i18n from 'i18next';
import { Toast } from '@swingvy/design-system';
import { ConstantsUtil } from '@swingvy/frontend-util';
import LocalStorage from 'js/storage/LocalStorage';
import DataProvider from 'js/network/DataProvider';
import API from 'js/network/IntroApi';

const { COUNTRY_CODE } = ConstantsUtil;

export const fetchGeolocationObservable = () => {
    return Observable.create((observer) => {
        const api =
            process.env.PHASE === 'real' || process.env.PHASE === 'demo'
                ? API.GEOLOCATION_REAL
                : API.GEOLOCATION_DEV;
        new DataProvider(api)
            .setCallback((result) => {
                try {
                    const realCountryCode = result.geolocation.country_code;
                    const allowedCountryCode = [
                        COUNTRY_CODE.MALAYSIA,
                        COUNTRY_CODE.SINGAPORE,
                        COUNTRY_CODE.TAIWAN,
                    ];
                    let geolocation = { countryCode: realCountryCode, realCountryCode };
                    if (!allowedCountryCode.includes(realCountryCode)) {
                        geolocation = { countryCode: COUNTRY_CODE.SINGAPORE, realCountryCode };
                    }
                    observer.next(geolocation);
                } catch (e) {
                    observer.next({ countryCode: COUNTRY_CODE.SINGAPORE, realCountryCode: null });
                }
            })
            .setErrorCallback((error) => {
                observer.next({ countryCode: COUNTRY_CODE.SINGAPORE, realCountryCode: null });
            })
            .request();
    }).retry(1);
};

export const fetchIndustriesObservable = () => {
    return Observable.create((observer) => {
        new DataProvider(API.BUSINESS_INDUSTRIES)
            .addQuery('languageCode', i18n.language)
            .setCallback((result) => {
                const industries = result.businessIndustries.map((bi) => ({
                    value: bi.id,
                    title: bi.name,
                }));
                observer.next(industries);
            })
            .setErrorCallback((error) => observer.error(error))
            .request();
    }).retry(1);
};

export const fetchCountriesObservable = () => {
    return Observable.create((observer) => {
        new DataProvider(API.COUNTRIES)
            .useCache(false)
            .addQuery('filter', 'office')
            .addQuery('languageCode', i18n.language)
            .setCallback((result) => {
                const countries = result.countries
                    .filter((country) => country.code !== 'KR')
                    .map((country) => ({
                        value: country.code,
                        title: country.name,
                    }));
                observer.next(countries);
            })
            .setErrorCallback((error) => observer.error(error))
            .request();
    }).retry(1);
};

export const fetchSubdivisionsObservable = (countryCode) => {
    return Observable.create((observer) => {
        new DataProvider(API.SUB_DIVISIONS, countryCode)
            .addQuery('languageCode', i18n.language)
            .setCallback((result) => {
                const countrySubdivisions = result.countrySubdivisions.map((subDivision) => ({
                    value: subDivision.id,
                    title: subDivision.name,
                }));
                observer.next(countrySubdivisions);
            })
            .setErrorCallback((error) => observer.error(error))
            .request();
    }).retry(1);
};

export const fetchMdhLocationsObservable = () => {
    return Observable.create((observer) => {
        new DataProvider(API.MDH_LOCATIONS)
            .setCallback((result) => {
                const mdhLocations = result.mdhLocations.map((mdhLocation) => ({
                    value: mdhLocation.id,
                    title: mdhLocation.name,
                }));
                observer.next(mdhLocations);
            })
            .setErrorCallback((error) => observer.error(error))
            .request();
    }).retry(1);
};
export const fetchStartupSelectPropertiesObservable = () => {
    return Observable.create((observer) => {
        new DataProvider(API.STARTUP_PROGRAM_SELECT_PROPERTIES)
            .setCallback((result) => {
                const { selectProperties } = result;
                const yearFoundedPresenter = selectProperties.yearFounded.map((item) => ({
                    value: item.id,
                    title: item.yearFounded,
                }));
                const fundingReceivedPresenter = selectProperties.fundingReceived.map((item) => ({
                    value: item.id,
                    title: item.fundingReceived,
                }));
                observer.next({
                    yearFounded: yearFoundedPresenter,
                    fundingReceived: fundingReceivedPresenter,
                });
            })
            .setErrorCallback((error) => observer.error(error))
            .request();
    }).retry(1);
};

export const fetchAllCountriesObservable = () => {
    return Observable.create((observer) => {
        new DataProvider(API.ALL_COUNTRIES)
            .setCallback((result) => {
                observer.next(result.countries);
            })
            .setErrorCallback((error) => observer.error(error))
            .request();
    }).retry(1);
};

export const fetchNumberOfEmployeeOptionsObservable = () => {
    return Observable.create((observer) => {
        new DataProvider(API.NUMBER_OF_EMPLOYEES_OPTIONS)
            .useCache(false)
            .addQuery('languageCode', i18n.language)
            .setCallback((result) => {
                const options = result.numberOfEmployeesOptions.map((option) => ({
                    value: option.name,
                    title: option.name,
                }));
                observer.next(options);
            })
            .setErrorCallback((error) => observer.error(error))
            .request();
    }).retry(1);
};

export const registerObservable = (targetApi, param) => {
    return Observable.create((observer) => {
        new DataProvider(targetApi)
            .setParam(JSON.stringify(param))
            .setCallback((result) => {
                observer.next({
                    meta: result.meta,
                    session: result.session,
                    key: result.key,
                });
            })
            .setErrorCallback((error) => {
                if (error.code) {
                    observer.error(error);
                } else {
                    Toast.Builder('error')
                        .setHeader(i18n.t('common.oops'))
                        .setBody(i18n.t('error.msg_temp_network'))
                        .build()
                        .show();
                }
            })
            .request();
    }).do(({ session }) => LocalStorage.setCurrentSession(session));
};

export const authRegisterDoneObservable = (param) => {
    return Observable.create((subscriber) => {
        $.ajax({
            type: API.REGISTER_DONE_JWT.method,
            url: `${API.REGISTER_DONE_JWT.url}?key=${encodeURIComponent(
                param.key,
            )}&email=${encodeURIComponent(param.email)}`,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
        })
            .done((data, textStatus, request) => subscriber.next(param))
            .fail((errorResult) => subscriber.error(errorResult));
    });
};
export const refreshTokenObservable = (param) => {
    return Observable.create((subscriber) => {
        $.ajax({
            type: API.REFRESH.method,
            url: API.REFRESH.url,
            dataType: 'json',
            contentType: 'application/json; charset=utf-8',
        })
            .done((data, textStatus, request) => subscriber.next(param))
            .fail((errorResult) => subscriber.error(errorResult));
    });
};
