import { Observable } from 'rxjs';
import React from 'react';
import { ConstantsUtil } from '@swingvy/frontend-util';
import Logger from 'js/helper/Logger';
import { renderReactElement } from 'js/util/ViewUtil';
import {
    fetchGeolocationObservable,
    fetchIndustriesObservable,
    fetchCountriesObservable,
    fetchSubdivisionsObservable,
    fetchMdhLocationsObservable,
    fetchStartupSelectPropertiesObservable,
    fetchAllCountriesObservable,
    fetchNumberOfEmployeeOptionsObservable,
} from './util';
import PageFrame from './PageFrame';
import RegisterContentView from './RegisterContentView';
import MdhRegisterContentView from './MdhRegisterContentView';
import StartupRegisterContentView from './StartupRegisterContentView';

const { COUNTRY_CODE } = ConstantsUtil;

const renderRegisterPage = ({
    industries = [],
    countries = [],
    subdivisions = [],
    allCountries = [],
    numberOfEmployeeOptions = [],
} = {}) => {
    const updateSubdivisions = (countryCode) => {
        if (!countryCode) return;
        fetchSubdivisionsObservable(countryCode).subscribe(
            (fetchedSubdivisions) =>
                renderRegisterPage({
                    industries,
                    countries,
                    subdivisions: fetchedSubdivisions,
                    allCountries,
                    numberOfEmployeeOptions,
                }),
            (error) => Logger.log(error),
        );
    };
    renderReactElement(
        <PageFrame>
            <RegisterContentView
                industries={industries}
                countries={countries}
                subdivisions={subdivisions}
                updateSubdivisions={updateSubdivisions}
                allCountries={allCountries}
                numberOfEmployeeOptions={numberOfEmployeeOptions}
            />
        </PageFrame>,
        document.getElementById('content'),
    );
};
const renderRegisterPageWithFetchData = () => {
    Observable.of('')
        .do(() => renderRegisterPage())
        .concatMap(() =>
            Observable.zip(
                fetchIndustriesObservable(),
                fetchCountriesObservable(),
                fetchAllCountriesObservable(),
                fetchNumberOfEmployeeOptionsObservable(),
                (industries, countries, allCountries, numberOfEmployeeOptions) => {
                    renderRegisterPage({
                        industries,
                        countries,
                        allCountries,
                        numberOfEmployeeOptions,
                    });
                },
            ),
        )
        .subscribe(
            (x) => {
                /* empty */
            },
            (error) => Logger.log(error),
        );
};

/* Mdec program으로 Register page */
const renderMdhRegisterPage = ({
    geolocation = null,
    industries = [],
    countries = [],
    subdivisions = [],
    mdhLocations = [],
} = {}) => {
    renderReactElement(
        <PageFrame>
            <MdhRegisterContentView
                geolocation={geolocation}
                industries={industries}
                countries={countries}
                subdivisions={subdivisions}
                mdhLocations={mdhLocations}
            />
        </PageFrame>,
        document.getElementById('content'),
    );
};
const renderMdhRegisterPageWithFetchData = () => {
    Observable.of('')
        .do(() => renderMdhRegisterPage())
        .concatMap(() =>
            Observable.zip(
                fetchGeolocationObservable(),
                fetchIndustriesObservable(),
                fetchCountriesObservable(),
                fetchSubdivisionsObservable(COUNTRY_CODE.MALAYSIA),
                fetchMdhLocationsObservable(),
                (geolocation, industries, countries, subdivisions, mdhLocations) =>
                    renderMdhRegisterPage({
                        geolocation,
                        industries,
                        countries,
                        subdivisions,
                        mdhLocations,
                    }),
            ),
        )
        .subscribe(
            (x) => {
                /* empty */
            },
            (error) => Logger.log(error),
        );
};

/* Startup program으로 Register page */
const renderStartupRegisterPage = ({
    geolocation = null,
    selectProperties = {},
    industries = [],
    countries = [],
    subdivisions = [],
    allCountries = [],
} = {}) => {
    const updateSubdivisions = (countryCode) => {
        if (!countryCode) return;
        fetchSubdivisionsObservable(countryCode).subscribe(
            (fetchedSubdivisions) =>
                renderStartupRegisterPage({
                    selectProperties,
                    industries,
                    countries,
                    subdivisions: fetchedSubdivisions,
                    allCountries,
                }),
            (error) => Logger.log(error),
        );
    };
    renderReactElement(
        <PageFrame>
            <StartupRegisterContentView
                geolocation={geolocation}
                fundingReceived={selectProperties.fundingReceived || []}
                yearFounded={selectProperties.yearFounded || []}
                industries={industries}
                countries={countries}
                subdivisions={subdivisions}
                updateSubdivisions={updateSubdivisions}
                allCountries={allCountries}
            />
        </PageFrame>,
        document.getElementById('content'),
    );
};
const renderStartupRegisterPageWithFetchData = () => {
    Observable.of('')
        .do(() => renderStartupRegisterPage())
        .concatMap(() =>
            Observable.zip(
                fetchGeolocationObservable(),
                fetchStartupSelectPropertiesObservable(),
                fetchIndustriesObservable(),
                fetchCountriesObservable(),
                fetchAllCountriesObservable(),
                (geolocation, selectProperties, industries, countries, allCountries) => {
                    fetchSubdivisionsObservable(geolocation.countryCode).subscribe(
                        (fetchedSubdivisions) =>
                            renderStartupRegisterPage({
                                geolocation,
                                selectProperties,
                                industries,
                                countries,
                                subdivisions: fetchedSubdivisions,
                                allCountries,
                            }),
                        (error) => Logger.log(error),
                    );
                },
            ),
        )
        .subscribe(
            (x) => {
                /* empty */
            },
            (error) => Logger.log(error),
        );
};

export const render = (pathArr) => {
    if (pathArr.length > 1) {
        switch (pathArr[1]) {
            case 'mdh':
                renderMdhRegisterPageWithFetchData();
                break;
            case 'startups':
                renderStartupRegisterPageWithFetchData();
                break;
            case 'pro':
            default:
                renderRegisterPageWithFetchData();
        }
    } else {
        renderRegisterPageWithFetchData();
    }
};
