const API_V1 = 'v1';
const API_V2 = 'v2';

export default {
    LOGIN_JWT: { url: '/api/auth/login', method: 'POST' },
    LOGIN: { url: `/api/auth/${API_V1}/loginJson`, method: 'POST' },
    REFRESH: { url: `/api/auth/${API_V1}/refresh`, method: 'POST' },
    REGISTER_DONE_JWT: { url: `/api/auth/${API_V1}/register/done`, method: 'GET' }, // ?key=&email=
    SESSION: { url: `/api/${API_V1}/session`, method: 'GET' },
    COUNTRIES: { url: `/api/${API_V1}/register/countries`, method: 'GET' },
    SUB_DIVISIONS: {
        url: `/api/${API_V1}/register/countries/{0}/subdivisions`,
        method: 'GET',
    }, // {0}=countryId
    SUB_DIVISION_DISTRICTS: {
        url: `/api/${API_V1}/register/countries/{0}/subdivisions/{1}/districts`,
        method: 'GET',
    }, // {0}=countryCode {1}=subdivisionCode
    BUSINESS_INDUSTRIES: { url: `/api/${API_V1}/register/businessIndustries`, method: 'GET' },
    MDH_LOCATIONS: { url: `/api/${API_V1}/mdec/external/mdhLocations`, method: 'GET' },
    STARTUP_PROGRAM_SELECT_PROPERTIES: {
        url: `/api/${API_V1}/startup-program/external/selectProperties`,
        method: 'GET',
    },
    REGISTER: { url: `/api/${API_V2}/register`, method: 'POST' },
    MDEC_REGISTER: { url: `/api/${API_V1}/mdec/external/register`, method: 'POST' },
    STARTUP_PROGRAM_REGISTER: {
        url: `/api/${API_V1}/startup-program/external/register`,
        method: 'POST',
    },
    RESET_MAIL: { url: `/api/${API_V1}/resetMail`, method: 'POST' },
    RESET_KEY: { url: `/api/${API_V1}/resetKey`, method: 'GET' },
    RESET_PASSWORD: { url: `/api/${API_V1}/resetPassword`, method: 'PATCH' },
    VERIFICATION_MAIL: { url: `/api/${API_V1}/verificationMail`, method: 'GET' },
    GEOLOCATION_DEV: {
        url: 'https://5ieq399z48.execute-api.ap-southeast-1.amazonaws.com/test/where-am-i',
        method: 'GET',
    },
    GEOLOCATION_REAL: {
        url: 'https://5ieq399z48.execute-api.ap-southeast-1.amazonaws.com/prod/where-am-i',
        method: 'GET',
    },
    ALL_COUNTRIES: { url: `/api/${API_V1}/register/countries`, method: 'GET' },
    NUMBER_OF_EMPLOYEES_OPTIONS: {
        url: `/api/${API_V1}/register/numberOfEmployeesOptions`,
        method: 'GET',
    },

    // On Boarding
    ON_BOARDING_VITALIZE: { url: `/api/${API_V1}/onboarding`, method: 'GET' },
    ON_BOARDING_SET_PASSWORD_KEY: {
        url: `/api/${API_V1}/onboarding/setPasswordKey`,
        method: 'GET',
    },
    ON_BOARDING_SET_PASSWORD: { url: `/api/${API_V1}/onboarding/password`, method: 'PATCH' },
};
