import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { Field, Input, Checkbox } from '@voyagers/form';
import { Button, ButtonSkin } from '@voyagers/button';
import { Toast } from '@swingvy/design-system';

import { rest } from 'js/network/apiConfig';
import { useSchema } from 'js/hooks/useSchema';
import { verificationSchema } from 'js/components/login/schema';
import { refreshTokenAndLogin } from 'js/app/view/login/LoginPage';

type VerifyFormValues = {
    code: string;
    trustDevice: boolean;
};

const VerificationPanel = ({ loginData }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        formState: { errors },
        clearErrors,
    } = useForm({
        resolver: useSchema(verificationSchema),
        defaultValues: { code: '', trustDevice: false },
    });

    const onSubmit: SubmitHandler<VerifyFormValues> = async (verificationData) => {
        setIsLoading(true);

        try {
            const { data: result } = await rest.session.requestVerification({
                ...loginData,
                ...verificationData,
            });
            await refreshTokenAndLogin(result);
        } catch (error) {
            const { code, message } = error;
            switch (code) {
                case 617: // incorrect code
                case 618: // expired code
                    setErrorMessage(message);
                    break;

                default:
                    Toast.Builder('error')
                        .setHeader(t('login.msg_ooops'))
                        .setBody(t('login.msg_temp_network_error'))
                        .build()
                        .show();
                    break;
            }
        } finally {
            setIsLoading(false);
        }
    };

    const resendCode = async () => {
        try {
            await rest.session.sendVerificationCode({ email: loginData.email });
            Toast.Builder('success').setHeader(t('Code is sent to your email.')).build().show();
        } catch (error) {
            Toast.Builder('error').setHeader(t('Something went wrong')).build().show();
        }
    };

    return (
        <div className="main-container _main_container">
            <div className="row main-inner-container">
                <div className="content-box col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                    <div className="content-inner-box login-view">
                        <div className="welcome-title">{t('Enter verification code')}</div>
                        <div style={{ margin: '8px 0 20px' }}>
                            <Trans
                                i18nKey="We've sent a 6-digit code to <b>{{email}}</b>. The code expires shortly, so please enter it soon."
                                values={{ email: loginData.email }}
                            />
                        </div>
                        <div>
                            <Field htmlFor="code" error={t(errors.code?.message || errorMessage)}>
                                <Controller
                                    name="code"
                                    control={control}
                                    render={({ field }) => (
                                        <Input
                                            type="text"
                                            maxLength={6}
                                            placeholder={t('Enter a 6-digit code')}
                                            onChange={(value) => {
                                                field.onChange(value);
                                                clearErrors('code');
                                                setErrorMessage('');
                                            }}
                                            error={errors.code || errorMessage}
                                            transform={{
                                                output: (value) => value.replace(/[^0-9]/gi, ''),
                                                input: (value) => value.toString(),
                                            }}
                                        />
                                    )}
                                />
                            </Field>
                        </div>
                        <div style={{ marginTop: 16 }}>
                            <Controller
                                name="trustDevice"
                                control={control}
                                render={({ field }) => (
                                    <Checkbox
                                        label={t("Don't ask me again on this device.")}
                                        value={field.value}
                                        checked={field.value}
                                        onChange={(_value, target) => field.onChange(target)}
                                    />
                                )}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: 30,
                            }}
                        >
                            <Button
                                skin={ButtonSkin.Primary}
                                size="large"
                                style={{ width: 283 }}
                                onClick={handleSubmit(onSubmit)}
                                loading={isLoading}
                            >
                                {t('common.submit')}
                            </Button>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: 4,
                                marginTop: 16,
                            }}
                        >
                            {t('Code expired?')}
                            <Button skin={ButtonSkin.TransparentPrimary} onClick={resendCode}>
                                {t('Resend code')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerificationPanel;
