import i18n from 'i18next';
import $ from 'jquery';
import React from 'react';
import DataProvider from 'js/network/DataProvider';
import API from 'js/network/IntroApi';
import RoutePath from 'js/app/RoutePath';
import { Banner, Button, Form, Input } from '@swingvy/design-system';

class ForgotPasswordView extends React.Component {
    constructor(props) {
        super(props);
        this.formEl = null;
        this.state = {
            email: '',
            isLoading: false,
            errorMessage: '',
            sendEmailSuccess: false,
        };
        this.sendEmailHandler = this.sendEmailHandler.bind(this);
    }

    sendEmailHandler() {
        this.setState({
            isLoading: true,
            errorMessage: '',
        });

        new DataProvider(API.RESET_MAIL)
            .addQuery('email', this.state.email)
            .setCallback((result) => {
                this.setState({
                    isLoading: false,
                    sendEmailSuccess: true,
                });
            })
            .setErrorCallback((error) => {
                this.setState({
                    isLoading: false,
                    errorMessage: error.code === 609 ? error.message : '',
                });
            })
            .request();
    }

    render() {
        return (
            <div className="swv-frame-layout">
                <div className="swv-content-container">
                    <div className="swv-container container-fluid">
                        <div className="header-container">
                            <div className="container-fluid">
                                <a href={RoutePath.SWINGVY_LANDING.HOME}>
                                    <div className="logo-img _swingvy_logo_img" />
                                </a>
                            </div>
                        </div>

                        <div className="main-container _main_container">
                            <div className="row main-inner-container">
                                <div className="content-box col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                                    {this.state.sendEmailSuccess ? (
                                        <div className="content-inner-box forgot-password">
                                            <div className="title">
                                                {i18n.t('forgot_password.email_sent.title')}
                                            </div>
                                            <div className="sub-title sent">
                                                {`${i18n.t(
                                                    'forgot_password.email_sent.subtitle1',
                                                )} `}
                                                <span className="email">{this.state.email}</span>
                                                {` ${i18n.t(
                                                    'forgot_password.email_sent.subtitle2',
                                                )}`}
                                            </div>
                                            <div className="resend-text">
                                                {i18n.t(
                                                    'forgot_password.email_sent.txt_ask_email_received',
                                                )}
                                                <span
                                                    className="retry"
                                                    onClick={(ev) => {
                                                        this.setState({
                                                            sendEmailSuccess: false,
                                                            email: '',
                                                        });
                                                    }}
                                                >{` ${i18n.t(
                                                    'forgot_password.email_sent.link_resend_email',
                                                )}`}</span>
                                            </div>
                                            <div className="resend-text">
                                                {`${i18n.t(
                                                    'forgot_password.email_sent.txt_return_to',
                                                )} `}
                                                <a className="retry" href="#/login">
                                                    {i18n.t(
                                                        'forgot_password.email_sent.link_login',
                                                    )}
                                                </a>
                                                {` ${i18n.t(
                                                    'forgot_password.email_sent.txt_page',
                                                )}`}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="content-inner-box forgot-password">
                                            <div className="title">
                                                {i18n.t('forgot_password.title')}
                                            </div>
                                            <div className="sub-title">
                                                {i18n.t('forgot_password.subtitle')}
                                            </div>
                                            <Banner
                                                type="error"
                                                className="action-error-banner"
                                                header={i18n.t(
                                                    'forgot_password.msg_cant_find_account',
                                                )}
                                                show={!!this.state.errorMessage}
                                                showCloseIcon={false}
                                            />
                                            <Form
                                                formRef={(el) => (this.formEl = el)}
                                                submitHandler={this.sendEmailHandler}
                                            >
                                                <Input
                                                    labelTitle={i18n.t(
                                                        'forgot_password.form.email.label',
                                                    )}
                                                    placeholder={i18n.t(
                                                        'forgot_password.form.email.placeholder',
                                                    )}
                                                    value={this.state.email}
                                                    required
                                                    type="email"
                                                    onChange={(value) =>
                                                        this.setState({ email: value })
                                                    }
                                                />
                                            </Form>
                                            <div className="submit-action-btn-box">
                                                <Button
                                                    className="btn-submit-action"
                                                    type="primary"
                                                    size="large"
                                                    title={i18n.t(
                                                        'forgot_password.form.submit_button.title',
                                                    )}
                                                    isLoading={this.state.isLoading}
                                                    onClick={(ev) => $(this.formEl).submit()}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="footer-container">
                            <div className="container">
                                <div className="link-item">
                                    <a
                                        href="https://www.swingvy.com/en/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {i18n.t('intro_footer.privacy.link')}
                                    </a>
                                </div>
                                <div className="link-item">
                                    <a
                                        href="https://www.swingvy.com/en/tos"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {i18n.t('intro_footer.terms.link')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPasswordView;
