import { useCallback } from 'react';
import { ObjectSchema } from 'yup';
import i18n from 'i18next';
import { toNestError } from '@hookform/resolvers';

type FieldError = {
    type: string;
    path: string;
    message: {
        key: string;
        values?: Record<string, unknown>;
    };
};

export const useSchema = (schema: ObjectSchema<unknown>) =>
    useCallback(
        async (data, _context, options) => {
            try {
                const values = await schema.validate(data, {
                    abortEarly: false,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors) {
                return {
                    values: {},
                    errors: toNestError(
                        (errors.inner || []).reduce(
                            (allErrors: Record<string, FieldError>, currentError: FieldError) => {
                                return {
                                    ...allErrors,
                                    [currentError.path]: {
                                        type: currentError.type ?? 'validation',
                                        message:
                                            i18n.t(
                                                currentError.message.key,
                                                currentError.message.values,
                                            ) || currentError.message,
                                        originalMessage: currentError.message,
                                    },
                                };
                            },
                            {},
                        ),
                        options,
                    ),
                };
            }
        },
        [schema],
    );
