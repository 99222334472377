import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { sendEvent, HrisEvents } from '@swingvy/frontend-util/data-events';
import { BillingService, LocationUtil } from '@swingvy/frontend-util';

import { rest } from 'js/network/apiConfig';
import LocalStorage from 'js/storage/LocalStorage';
import RoutePath from 'js/app/RoutePath';
import LoginPanel, { StyledLink } from 'js/components/login/LoginPanel';
import VerificationPanel from 'js/components/login/VerificationPanel';

const { CommonEventName } = HrisEvents;
const billingService = new BillingService(null);

export const refreshTokenAndLogin = async (loginResponse) => {
    await rest.session.refreshToken();
    // 1. save data to localStorage
    const { session } = loginResponse.data;
    LocalStorage.setCurrentSession(session);
    // 2. track data
    try {
        const { user, company } = session;
        sendEvent(CommonEventName.LOGIN, {
            user_id: user.id,
            email: user.email,
            name: user.name,
            country: user.country,
            company_id: company.id,
            company_name: company.name,
        });
    } catch (e) {
        sendEvent(CommonEventName.LOGIN);
    }
    // go to next page
    const redirectPath = LocationUtil.getParamValue('redirect', document.location.href);
    if (redirectPath) {
        const isSuspended = billingService.isSuspended(session);
        if (isSuspended) {
            document.location.href = '#/';
        } else {
            const replaceUrl = `#/login?redirect=${encodeURIComponent(redirectPath)}`;
            if (document.location.hash === replaceUrl) {
                document.location.replace(replaceUrl);
                document.location.reload();
            } else {
                document.location.replace(replaceUrl);
            }
        }
    } else {
        document.location.href = '#/';
    }
};

const LoginPage = (props) => {
    const [stage, setStage] = useState('login');
    const [loginData, setLoginData] = useState({});
    const { t } = useTranslation();

    return (
        <div className="swv-frame-layout" style={{ fontFamily: 'CircularXX-TT' }}>
            <div className="swv-content-container">
                <div className="swv-container container-fluid">
                    <div className="header-container">
                        <div className="container-fluid">
                            <a className="_swingvy_logo_link" href={RoutePath.SWINGVY_LANDING.HOME}>
                                <div className="logo-img _swingvy_logo_img" />
                            </a>
                        </div>
                    </div>
                    {stage === 'verify' ? (
                        <VerificationPanel loginData={loginData} />
                    ) : (
                        <LoginPanel {...props} setStage={setStage} setLoginData={setLoginData} />
                    )}
                    <div className="footer-container">
                        <div className="container">
                            <div className="link-item">
                                <StyledLink
                                    href="https://www.swingvy.com/en/privacy"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('intro_footer.privacy.link')}
                                </StyledLink>
                            </div>
                            <div className="link-item">
                                <StyledLink
                                    href="https://www.swingvy.com/en/tos"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('intro_footer.terms.link')}
                                </StyledLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
