import React from 'react';
import { ArrayUtil, LocationUtil } from '@swingvy/frontend-util';
import RoutePath from 'js/app/RoutePath';
import i18n from 'i18next';

const MdecCheckView = () => {
    const pathArr = LocationUtil.getHashPaths();
    let presenter = {
        title: '',
        message: '',
        privacy: i18n.t('intro_footer.privacy.link'),
        terms: i18n.t('intro_footer.terms.link'),
    };
    if (ArrayUtil.includes(pathArr, 'approve')) {
        presenter = {
            title: i18n.t('mdec_check.approve.title'),
            message: i18n.t('mdec_check.approve.subtitle'),
        };
    } else if (ArrayUtil.includes(pathArr, 'reject')) {
        presenter = {
            title: i18n.t('mdec_check.reject.title'),
            message: i18n.t('mdec_check.reject.subtitle'),
        };
    } else if (ArrayUtil.includes(pathArr, 'already')) {
        presenter = {
            title: i18n.t('mdec_check.already.title'),
            message: i18n.t('mdec_check.already.subtitle'),
        };
    }

    return (
        <div className="swv-frame-layout">
            <div className="swv-content-container">
                <div className="swv-container container-fluid">
                    <div className="header-container register-header-container">
                        <div className="container-fluid">
                            <a href={RoutePath.SWINGVY_LANDING.HOME}>
                                <div className="logo-img _swingvy_logo_img" />
                            </a>
                        </div>
                    </div>

                    <div className="main-container _main_container">
                        <div className="row main-inner-container">
                            <div className="content-box col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                                <div className="content-inner-box check-view">
                                    <div className="title">{presenter.title}</div>
                                    <div className="sub-title">{presenter.message}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-container">
                        <div className="container">
                            <div className="link-item">
                                <a
                                    href="https://www.swingvy.com/en/privacy"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {presenter.privacy}
                                </a>
                            </div>
                            <div className="link-item">
                                <a
                                    href="https://www.swingvy.com/en/tos"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {presenter.terms}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

MdecCheckView.propTypes = {
    // proPlus: PropTypes.bool,
    // mdhProgram: PropTypes.bool
};

MdecCheckView.defaultProps = {
    // proPlus: false,
    // mdhProgram: false
};

export default MdecCheckView;
