import i18n from 'i18next';
import { WithMobileAppButton } from 'js/components/common/native-app/WithMobileAppButton';
import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import DataProvider from 'js/network/DataProvider';
import API from 'js/network/IntroApi';
import RoutePath from 'js/app/RoutePath';
import { Banner, Button, Form, Input } from '@swingvy/design-system';

class NewPasswordView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            hasKeyExpiredError: false,
            hasTechnicalError: false,
            password: '',
            confirmPassword: '',
            resetPasswordSuccess: false,
        };
        this.changePasswordHandler = this.changePasswordHandler.bind(this);
        this.getPasswordChangedView = this.getPasswordChangedView.bind(this);
        this.getKeyExpiredView = this.getKeyExpiredView.bind(this);
    }

    changePasswordHandler() {
        this.setState({
            isLoading: true,
            hasKeyExpiredError: false,
            hasTechnicalError: false,
        });

        const param = {
            newPassword: this.state.password,
        };
        new DataProvider(API.RESET_PASSWORD)
            .addQuery('q', this.props.q)
            .setParam(JSON.stringify(param))
            .setCallback((result) => {
                this.setState({
                    isLoading: false,
                    resetPasswordSuccess: true,
                });
            })
            .setErrorCallback((error) => {
                this.setState({ isLoading: false, hasTechnicalError: true });
            })
            .request();
    }

    getPasswordChangedView() {
        return (
            <div className="content-inner-box forgot-password">
                <div className="title">{i18n.t('new_password.password_changed.title')}</div>
                <div className="sub-title sent">
                    {i18n.t('new_password.password_changed.subtitle')}
                </div>
                <div className="resend-text">
                    <WithMobileAppButton
                        deepLinkParameters="?redirect=login&passwordChanged=true"
                        webRoutePath={RoutePath.LOGIN}
                    >
                        <div>
                            <span>
                                {i18n.t('new_password.password_changed.go_login_page.text')}
                            </span>
                            <span
                                className="retry"
                                onClick={(ev) => {
                                    window.location.replace(RoutePath.LOGIN);
                                }}
                            >{` ${i18n.t(
                                'new_password.password_changed.go_login_page.link',
                            )}`}</span>
                        </div>
                    </WithMobileAppButton>
                </div>
            </div>
        );
    }

    getKeyExpiredView() {
        return (
            <div className="content-inner-box forgot-password">
                <div className="title">{i18n.t('new_password.link_expired.title')}</div>
                <div className="sub-title sent">{i18n.t('new_password.link_expired.subtitle')}</div>
                <Button
                    type="primary"
                    title={i18n.t('new_password.link_expired.button')}
                    onClick={(ev) => {
                        window.location.replace(RoutePath.FORGOT_PASSWORD);
                    }}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="swv-frame-layout">
                <div className="swv-content-container">
                    <div className="swv-container container-fluid">
                        <div className="header-container">
                            <div className="container-fluid">
                                <a href={RoutePath.SWINGVY_LANDING.HOME}>
                                    <div className="logo-img _swingvy_logo_img" />
                                </a>
                            </div>
                        </div>

                        <div className="main-container _main_container">
                            <div className="row main-inner-container">
                                <div className="content-box col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                                    {this.state.resetPasswordSuccess ? (
                                        this.getPasswordChangedView()
                                    ) : this.state.hasKeyExpiredError ? (
                                        this.getKeyExpiredView()
                                    ) : (
                                        <div className="content-inner-box new-password">
                                            <div className="title">
                                                {i18n.t('new_password.title')}
                                            </div>
                                            <Banner
                                                type="error"
                                                className="action-error-banner"
                                                header={i18n.t(
                                                    'new_password.msg_unknown_error_reset_password',
                                                )}
                                                show={this.state.hasTechnicalError}
                                                showCloseIcon={false}
                                            />
                                            <Form
                                                formRef={(el) => (this.formEl = el)}
                                                submitHandler={this.changePasswordHandler}
                                            >
                                                <Input
                                                    type="password"
                                                    onIdCreated={(id) => (this.passwordId = id)}
                                                    labelTitle={i18n.t(
                                                        'new_password.form.password.label',
                                                    )}
                                                    value={this.state.password}
                                                    minLength={8}
                                                    required
                                                    onChange={(value) =>
                                                        this.setState({ password: value })
                                                    }
                                                />
                                                <Input
                                                    type="password"
                                                    labelTitle={i18n.t(
                                                        'new_password.form.confirm_password.label',
                                                    )}
                                                    equalTo={`#${this.passwordId}`}
                                                    value={this.state.confirmPassword}
                                                    minLength={8}
                                                    required
                                                    onChange={(value) =>
                                                        this.setState({ confirmPassword: value })
                                                    }
                                                />
                                            </Form>
                                            <Button
                                                className="btn-submit-action"
                                                type="primary"
                                                title={i18n.t(
                                                    'new_password.form.submit_button.title',
                                                )}
                                                isLoading={this.state.isLoading}
                                                onClick={(ev) => $(this.formEl).submit()}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="footer-container">
                            <div className="container">
                                <div className="link-item">
                                    <a
                                        href="https://www.swingvy.com/en/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {i18n.t('intro_footer.privacy.link')}
                                    </a>
                                </div>
                                <div className="link-item">
                                    <a
                                        href="https://www.swingvy.com/en/tos"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {i18n.t('intro_footer.terms.link')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NewPasswordView.propTypes = {
    q: PropTypes.string.isRequired,
};

export default NewPasswordView;
