import React from 'react';
import i18n from 'i18next';
import { SwingvyUtil } from '@swingvy/frontend-util';
import { Button } from '@swingvy/design-system';
import { WithMobileAppButton } from 'js/components/common/native-app/WithMobileAppButton';
import IntroApi from 'js/network/IntroApi';
import DataProvider from 'js/network/DataProvider';
import AbsPage from 'js/components/common/AbsPage';
import RoutePath from 'js/app/RoutePath';
import OnBoardingSetPassword from 'js/onboarding_app/view/OnBoardingSetPassword';
import OnBoardingCompleteModal from 'js/onboarding_app/view/OnBoardingCompleteModal';

class OnBoardingApp extends AbsPage {
    constructor(props) {
        super(props);
        this.state = {
            status: null,
            user: null,
            company: null,
            showOnBoardingCompleteModal: false,
        };
        this.checkUserStatus = this.checkUserStatus.bind(this);
        this.renderOnBoardingPage = this.renderOnBoardingPage.bind(this);
        this.statusChange = this.statusChange.bind(this);
        this.showOnBoardingCompleteModal = this.showOnBoardingCompleteModal.bind(this);
        this.hideOnBoardingCompleteModal = this.hideOnBoardingCompleteModal.bind(this);
        this.checkUserStatus();
    }

    statusChange(status) {
        this.state.user.status = status;
        this.setState({
            status,
            user: this.state.user,
        });
    }

    showOnBoardingCompleteModal() {
        this.setState({
            showOnBoardingCompleteModal: true,
        });
    }

    hideOnBoardingCompleteModal() {
        this.setState({
            showOnBoardingCompleteModal: false,
        });

        let href = '/#/login';
        if (this.state.user) {
            href = `${href}?email=${this.state.user.email}`;
        }
        location.href = href;
    }

    checkUserStatus() {
        const self = this;
        const dataProvider = new DataProvider(IntroApi.ON_BOARDING_VITALIZE);
        dataProvider
            .addQuery('q', this.props.q)
            .setCallback((result) => {
                const { user } = result.session;
                self.setState({
                    status: user.status,
                    user,
                    company: result.session.company,
                });
            })
            .setErrorCallback((err) => {
                if (err.code === 100006) {
                    self.showOnBoardingCompleteModal();
                }
            })
            .request();
    }

    renderOnBoardingPage() {
        const { user } = this.state;
        const userStatus = SwingvyUtil.userStatus(user);
        if (userStatus.isOnBoardingProgressUser()) {
            return (
                <OnBoardingSetPassword
                    user={user}
                    q={this.props.q}
                    status={this.state.status}
                    userPasswordSetAnd={this.statusChange}
                />
            );
        }
        if (userStatus.isOnBoardingUser()) {
            const subTitleArr = i18n.t('onboarding.done.subtitle', { enter: '\\n' }).split('\\n');
            return (
                <div>
                    <div className="content-title">
                        {i18n.t('onboarding.done.title', { userName: user.fullName })}
                    </div>
                    <div className="content-sub-title sent">
                        {subTitleArr.map((item, idx) => (
                            <div key={idx}>{item}</div>
                        ))}
                    </div>

                    <WithMobileAppButton
                        deepLinkParameters="?redirect=login"
                        webRoutePath={RoutePath.LOGIN}
                    >
                        <Button
                            type="primary"
                            title={i18n.t('onboarding.done.button.title')}
                            onClick={(ev) => window.location.replace('#/login')}
                        />
                    </WithMobileAppButton>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="swv-frame-layout">
                <div className="swv-content-container">
                    <div className="swv-container container-fluid">
                        <div className="header-container">
                            <div className="container-fluid">
                                <a href={RoutePath.SWINGVY_LANDING.HOME}>
                                    <div className="logo-img _swingvy_logo_img" />
                                </a>
                            </div>
                        </div>

                        <div className="_main_container main-container">
                            <div className="row main-inner-container">
                                <div className="content-box col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                                    <div className="content-inner-box employee-onboarding">
                                        {this.state.user && this.renderOnBoardingPage()}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="footer-container">
                            <div className="container">
                                <div className="link-item">
                                    <a
                                        href="https://www.swingvy.com/en/privacy"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {i18n.t('intro_footer.privacy.link')}
                                    </a>
                                </div>
                                <div className="link-item">
                                    <a
                                        href="https://www.swingvy.com/en/tos"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {i18n.t('intro_footer.terms.link')}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <OnBoardingCompleteModal
                            show={this.state.showOnBoardingCompleteModal}
                            message={i18n.t('onboarding.msg_already_on_boarding_complete')}
                            onClick={this.hideOnBoardingCompleteModal}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default OnBoardingApp;
