import PropTypes from 'prop-types';
import $ from 'jquery';
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@swingvy/design-system';

const ErrorModal = (props) => {
    const {
        show,
        className,
        showCloseBtn,
        type,
        title,
        message,
        onHide,

        cancelBtnTitle,
        onClickCancelBtn,
        submitBtnTitle,
        onClickSubmitBtn,
    } = props;

    return (
        <Modal className={className} show={show} onHide={onHide} type={type}>
            <ModalHeader title={title} showCloseBtn={showCloseBtn} />
            <ModalBody>{message}</ModalBody>
            <ModalFooter
                showCancelBtn={!!cancelBtnTitle}
                cancelBtnTitle={cancelBtnTitle}
                onClickCancelBtn={onClickCancelBtn}
                showSubmitBtn={!!submitBtnTitle}
                submitBtnTitle={submitBtnTitle}
                onClickSubmitBtn={onClickSubmitBtn}
            />
        </Modal>
    );
};

ErrorModal.propTypes = {
    show: PropTypes.bool.isRequired,
    className: PropTypes.string,
    onHide: PropTypes.func,
    type: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    showCloseBtn: PropTypes.bool,

    cancelBtnTitle: PropTypes.string,
    onClickCancelBtn: PropTypes.func,
    submitBtnTitle: PropTypes.string,
    onClickSubmitBtn: PropTypes.func,
};

ErrorModal.defaultProps = {
    className: '',
    type: 'critical',
    title: 'Error',
    message: 'Unexpected error occurred',
    showCloseBtn: true,

    cancelBtnTitle: null,
    onClickCancelBtn: null,
    submitBtnTitle: null,
    onClickSubmitBtn: null,
};

export default ErrorModal;
