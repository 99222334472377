import React from 'react';
import { Button } from '@swingvy/design-system';
import i18n from 'i18next';

export const isIos = navigator.userAgent.match(/iPhone/i);
export const isAndroid = navigator.userAgent.match(/Android/i);

export const WithMobileAppButton = ({ children = null, deepLinkParameters, webRoutePath }) => {
    if (isIos || isAndroid) {
        return (
            <div className="native-app">
                <Button
                    type="primary"
                    className="button"
                    title={i18n.t('new_password.password_changed.continue_app_text')}
                    onClick={() =>
                        location.replace(
                            `https://mobile.swingvy.com/${
                                isIos ? 'ios' : 'android'
                            }${deepLinkParameters}`,
                        )
                    }
                />
                <div
                    className="retry"
                    onClick={() => {
                        location.replace(webRoutePath);
                    }}
                >
                    {i18n.t('new_password.password_changed.continue_mobile_web_text')}
                </div>
            </div>
        );
    }
    return children;
};
