import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { ConstantsUtil } from '@swingvy/frontend-util';
import { sendEvent, HrisEvents } from '@swingvy/frontend-util/data-events';
import { Banner, Button, Form, Input, Select } from '@swingvy/design-system';

import API from 'js/network/IntroApi';
import RoutePath from 'js/app/RoutePath';
import ErrorModal from 'js/components/common/modal/ErrorModal';
import { registerObservable, authRegisterDoneObservable, refreshTokenObservable } from './util';

import 'style-loader!./register-content.less';

const { CommonEventName } = HrisEvents;
const { COUNTRY_CODE } = ConstantsUtil;
const ICON_INFO = require('-!svg-react-loader!Icons/ic-info.svg');

const MAILTO_SUPPORT = 'mailto:support@swingvy.com';

class MdhRegisterContentView extends Component {
    constructor(props) {
        super(props);
        this.formEl = null;
        this.passwordId = null;

        this.state = {
            fullName: '',
            companyName: '',
            email: '',
            password: '',
            confirmPassword: '',
            noOfEmployee: '',
            selectedIndustry: null,
            selectedCountry: COUNTRY_CODE.MALAYSIA,
            realCountryCode: null,
            selectedSubdivision: null,
            selectedMdhLocation: null,
            isLoading: false,
            emailVerifyModal: {
                show: false,
                email: null,
                key: null,
            },
            showMdhEmailVerifyBanner: false,
        };
        this.registerCompanyHandler = this.registerCompanyHandler.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.geolocation) {
            if (nextProps.geolocation.realCountryCode !== this.state.realCountryCode) {
                this.setState({ realCountryCode: nextProps.geolocation.realCountryCode });
            }
        }
    }

    registerCompanyHandler() {
        this.setState({ isLoading: true });

        Observable.of({
            fullName: this.state.fullName,
            companyName: this.state.companyName,
            email: this.state.email,
            password: this.state.password,
            noOfEmployee: this.state.noOfEmployee,
            businessIndustryId: this.state.selectedIndustry,
            countryCode: this.state.selectedCountry,
            realCountryCode: this.state.realCountryCode,
            countrySubdivisionId: this.state.selectedSubdivision,
            mdhLocationId: this.state.selectedMdhLocation,
        })
            .concatMap((param) => registerObservable(API.MDEC_REGISTER, param))
            .subscribe(
                (result) => {
                    try {
                        const { user, company } = result.session;
                        sendEvent(CommonEventName.SIGN_UP, {
                            user_id: user.id,
                            email: user.email,
                            name: user.name,
                            company_id: company.id,
                            company_name: company.name,
                            phone_number: '',
                            business_industry: this.state.selectedIndustry,
                            number_of_employees: this.state.noOfEmployee,
                            country: this.state.selectedCountry,
                            real_country: this.state.realCountryCode,
                            register_type: 'mdh',
                        });
                    } catch (e) {
                        sendEvent(CommonEventName.SIGN_UP);
                        console.log(e);
                    }

                    this.setState({
                        isLoading: false,
                        emailVerifyModal: {
                            show: true,
                            key: result.key,
                            email: this.state.email,
                        },
                    });
                },
                (error) => {
                    const nextState = { isLoading: false };
                    switch (error.code) {
                        case 701:
                            nextState.showMdhEmailVerifyBanner = true;
                            break;
                    }
                    this.setState(nextState);
                },
            );
    }

    render() {
        const { industries, countries, subdivisions, mdhLocations } = this.props;

        return (
            <React.Fragment>
                <div className="content-box company-register-box mdh-company-register-box">
                    <div className="content-inner-box">
                        <div className="title-area">
                            <div className="content-title">{i18n.t('register.title')}</div>
                            <div className="content-sub-title">
                                {i18n.t('register.register--mdh.subtitle')}
                            </div>
                        </div>
                        <div className="register-warning-box">
                            <ICON_INFO />
                            <div className="warning-text">
                                {`${i18n.t('register.email_exist_info.text1')} `}
                                <a href={MAILTO_SUPPORT}>
                                    {i18n.t('register.email_exist_info.text2_link')}
                                </a>
                                {` ${i18n.t('register.email_exist_info.text3')}`}
                            </div>
                        </div>
                        <div
                            className={`register-error-box ${
                                this.state.showMdhEmailVerifyBanner ? 'show-el' : ''
                            }`}
                        >
                            <Banner
                                type="error"
                                header={
                                    <span>
                                        {`${i18n.t('register.error.duplicate_email.text1')} `}
                                        <a href={MAILTO_SUPPORT}>
                                            {i18n.t(
                                                'register.error.duplicate_email.text2_support_team',
                                            )}
                                        </a>
                                        {` ${i18n.t('register.error.duplicate_email.text3')}`}
                                    </span>
                                }
                                onClickClose={(ev) =>
                                    this.setState({ showMdhEmailVerifyBanner: false })
                                }
                            />
                        </div>
                        <Form
                            formRef={(el) => (this.formEl = el)}
                            submitHandler={this.registerCompanyHandler}
                        >
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <Input
                                        labelTitle={i18n.t('form.fullname.label')}
                                        required
                                        value={this.state.fullName}
                                        onChange={(value) => this.setState({ fullName: value })}
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <Input
                                        labelTitle={i18n.t('register.form.company_name.label')}
                                        required
                                        value={this.state.companyName}
                                        onChange={(value) => this.setState({ companyName: value })}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <Input
                                        labelTitle={i18n.t('form.email.label')}
                                        type="email"
                                        required
                                        value={this.state.email}
                                        onChange={(value) => this.setState({ email: value })}
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <Select
                                        labelTitle="Business industry"
                                        placeholder="Select business industry"
                                        required
                                        options={industries}
                                        value={this.state.selectedIndustry}
                                        onChange={(option) =>
                                            this.setState({ selectedIndustry: option.value })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <Input
                                        labelTitle={i18n.t('form.password.label')}
                                        type="password"
                                        onIdCreated={(id) => (this.passwordId = id)}
                                        required
                                        minLength={8}
                                        value={this.state.password}
                                        onChange={(value) => this.setState({ password: value })}
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <Input
                                        labelTitle={i18n.t('form.password.label_confirm')}
                                        type="password"
                                        equalTo={`#${this.passwordId}`}
                                        required
                                        minLength={8}
                                        value={this.state.confirmPassword}
                                        onChange={(value) =>
                                            this.setState({ confirmPassword: value })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <Select
                                        labelTitle={i18n.t(
                                            'register.register--mdh.form.mdh_location.label',
                                        )}
                                        placeholder={i18n.t(
                                            'register.register--mdh.form.mdh_location.placeholder',
                                        )}
                                        required
                                        options={mdhLocations}
                                        value={this.state.selectedMdhLocation}
                                        onChange={(option) =>
                                            this.setState({ selectedMdhLocation: option.value })
                                        }
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <Input
                                        className="number-of-employee-input"
                                        labelTitle={i18n.t('form.no_of_employees.label')}
                                        type="number"
                                        min={1}
                                        max={50000}
                                        required
                                        value={this.state.noOfEmployee}
                                        onChange={(value) => this.setState({ noOfEmployee: value })}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <Select
                                        labelTitle={i18n.t('register.form.country.label')}
                                        placeholder={i18n.t('register.form.country.placeholder')}
                                        required
                                        disabled
                                        options={countries}
                                        value={this.state.selectedCountry}
                                        onChange={(option) =>
                                            this.setState({ selectedCountry: option.value })
                                        }
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <Select
                                        labelTitle={i18n.t('register.form.state.label')}
                                        placeholder={i18n.t('register.form.state.placeholder')}
                                        required
                                        options={subdivisions}
                                        value={this.state.selectedSubdivision}
                                        onChange={(option) =>
                                            this.setState({ selectedSubdivision: option.value })
                                        }
                                    />
                                </div>
                            </div>
                        </Form>
                        <div className="company-sign-up-box">
                            <div className="agree-check">
                                <span>
                                    {`${i18n.t('register.form.agreement.text1')} `}
                                    <a
                                        className="swv-btn swv-btn-links swv-theme-light"
                                        href={RoutePath.SWINGVY_LANDING.TOS}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {i18n.t('register.form.agreement.text2_link_terms')}
                                    </a>{' '}
                                    &{' '}
                                    <a
                                        className="swv-btn swv-btn-links swv-theme-light"
                                        href={RoutePath.SWINGVY_LANDING.PRIVACY}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {i18n.t(
                                            'register.form.agreement.text3_link_privacy_policy',
                                        )}
                                    </a>
                                    .
                                </span>
                            </div>
                            <Button
                                className="btn-company-sign-up"
                                type="primary"
                                size="large"
                                title={i18n.t('register.form.submit_button.title')}
                                isLoading={this.state.isLoading}
                                onClick={(ev) => $(this.formEl).submit()}
                            />
                        </div>
                    </div>
                </div>

                <div className="login-guide-box">{/* just for margin */}</div>

                <ErrorModal
                    className="gtm-verify-email-modal gtm-verify-email-modal--mdh"
                    show={this.state.emailVerifyModal.show}
                    showCloseBtn={false}
                    type="standard"
                    title={i18n.t('register.verify_email_modal.header')}
                    message={i18n.t('register.verify_email_modal.message')}
                    cancelBtnTitle={i18n.t('register.verify_email_modal.button_title')}
                    onClickCancelBtn={() => {
                        this.setState(
                            {
                                emailVerifyModal: { ...this.state.emailVerifyModal, show: false },
                            },
                            () => {
                                const { emailVerifyModal } = this.state;
                                authRegisterDoneObservable({
                                    key: emailVerifyModal.key,
                                    email: emailVerifyModal.email,
                                })
                                    .concatMap((param) => refreshTokenObservable(param))
                                    .subscribe((param) => {
                                        document.location.href = '/main.html';
                                    });
                            },
                        );
                    }}
                />
            </React.Fragment>
        );
    }
}

MdhRegisterContentView.propTypes = {
    industries: PropTypes.array,
    countries: PropTypes.array,
    subdivisions: PropTypes.array,
    mdhLocations: PropTypes.array,
};

export default MdhRegisterContentView;
