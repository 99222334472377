import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonSkin, ButtonSize } from '@voyagers/button';
import { LocationUtil } from '@swingvy/frontend-util';

import RoutePath from 'js/app/RoutePath';
import { WithMobileAppButton } from 'js/components/common/native-app/WithMobileAppButton';

const DownloadPayslip = () => {
    const redirectPath = `/?redirect=${RoutePath.PAYSLIP_ENTRY}`.replace('#', '');
    const userId = LocationUtil.getParamValue('userId');
    const { t } = useTranslation();

    return (
        <div className="swv-frame-layout">
            <div className="swv-content-container">
                <div className="swv-container container-fluid">
                    <div className="header-container">
                        <div className="container-fluid">
                            <a href={RoutePath.SWINGVY_LANDING.HOME}>
                                <div className="logo-img _swingvy_logo_img" />
                            </a>
                        </div>
                    </div>
                    <div className="main-container _main_container">
                        <div className="row main-inner-container">
                            <div className="content-box col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                                <div className="content-inner-box forgot-password">
                                    <h2 className="title">{t('Ooops!')}</h2>
                                    <br />
                                    <div className="sub-title">
                                        {t(
                                            "This payslip is not valid anymore, please contact your company's payroll admin.",
                                        )}
                                    </div>
                                    <WithMobileAppButton
                                        deepLinkParameters={`?redirect=payslip-list${
                                            userId ? `&userId=${userId}` : ''
                                        }`}
                                        webRoutePath={redirectPath}
                                    >
                                        <Button
                                            size={ButtonSize.Large}
                                            skin={ButtonSkin.Primary}
                                            onClick={() => {
                                                document.location.replace(redirectPath);
                                            }}
                                        >
                                            {t('View Historical Payslips')}
                                        </Button>
                                    </WithMobileAppButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-container">
                        <div className="container">
                            <div className="link-item">
                                <a
                                    href="https://www.swingvy.com/en/privacy"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('intro_footer.privacy.link')}
                                </a>
                            </div>
                            <div className="link-item">
                                <a
                                    href="https://www.swingvy.com/en/tos"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {t('intro_footer.terms.link')}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadPayslip;
