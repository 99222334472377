import i18n from 'i18next';
import PropTypes from 'prop-types';
import $ from 'jquery';
import React from 'react';
import IntroApi from 'js/network/IntroApi';
import DataProvider from 'js/network/DataProvider';
import { Banner, Button, Form, Input } from '@swingvy/design-system';

class SetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.formEl = null;
        this.state = {
            password: '',
            confirmPassword: '',
            isChanging: false,
            errorMessage: '',
        };
        this.setPasswordHandler = this.setPasswordHandler.bind(this);
    }

    setPasswordHandler() {
        this.setState({ isChanging: true, errorMessage: '' });

        const { q, status } = this.props;
        new DataProvider(IntroApi.ON_BOARDING_SET_PASSWORD)
            .setParam(
                JSON.stringify({
                    userStatus: { code: status },
                    password: this.state.password,
                }),
            )
            .addQuery('q', q)
            .setCallback((result) => {
                this.setState({ isChanging: false });
                this.props.userPasswordSetAnd(Number(result.status));
            })
            .setErrorCallback((error) => {
                this.setState({ isChanging: false });
            })
            .request();
    }

    render() {
        return (
            <div>
                <div
                    className="content-title"
                    style={{ textAlign: 'center', marginBottom: '16px' }}
                >
                    {i18n.t('onboarding.set_password.title_v2')}
                </div>
                <div className="content-description" style={{ textAlign: 'center' }}>
                    {i18n.t('onboarding.set_password.description')}
                </div>
                <Banner
                    type="error"
                    className="action-error-banner"
                    header={i18n.t('onboarding.set_password.msg_unknown_error_reset_password')}
                    show={!!this.state.errorMessage}
                    showCloseIcon={false}
                />
                <Form formRef={(el) => (this.formEl = el)} submitHandler={this.setPasswordHandler}>
                    <Input
                        type="password"
                        onIdCreated={(id) => (this.passwordId = id)}
                        labelTitle={i18n.t('form.password.label')}
                        minLength={8}
                        value={this.state.password}
                        required
                        onChange={(value) => this.setState({ password: value })}
                    />
                    <Input
                        type="password"
                        labelTitle={i18n.t('form.password.label_confirm')}
                        minLength={8}
                        equalTo={`#${this.passwordId}`}
                        value={this.state.confirmPassword}
                        required
                        onChange={(value) => this.setState({ confirmPassword: value })}
                    />
                </Form>
                <Button
                    className="btn-submit-action"
                    type="primary"
                    title={i18n.t('onboarding.set_password.submit_button.title')}
                    isLoading={this.state.isChanging}
                    onClick={(ev) => $(this.formEl).submit()}
                />
            </div>
        );
    }
}

SetPassword.propTypes = {
    user: PropTypes.object,
    q: PropTypes.string,
    status: PropTypes.number,
    userPasswordSetAnd: PropTypes.func,
};

export default SetPassword;
