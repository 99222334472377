import { object, string, boolean, setLocale } from 'yup';

setLocale({
    mixed: {
        required: { key: 'This field is required.' },
    },
    string: {
        email: { key: 'form.validation_error_msg.invalid_email_address' },
        length: ({ length }) => ({
            key: 'This field should be {{length}} characters.',
            values: { length },
        }),
    },
});

export const loginSchema = object({
    email: string().trim().email().required(),
    password: string().required(),
});

export const verificationSchema = object({
    code: string().length(6).required(),
    trustDevice: boolean(),
});
