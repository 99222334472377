import i18n from 'i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '@swingvy/design-system';

const OnBoardingCompleteModal = (props) => {
    const { show, message, onClick } = props;

    return (
        <Modal show={show}>
            <ModalHeader title={i18n.t('onboarding.complete_modal.title')} />
            <ModalBody>{message}</ModalBody>
            <ModalFooter
                showCancelBtn={false}
                submitBtnTitle={i18n.t('onboarding.complete_modal.submit_button.title')}
                onClickSubmitBtn={onClick}
            />
        </Modal>
    );
};

OnBoardingCompleteModal.propTypes = {
    show: PropTypes.bool,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onClick: PropTypes.func,
};

export default OnBoardingCompleteModal;
